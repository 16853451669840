import { GREY, COMMON, PRIMARY, GRADIENTS } from '@theme/modes/common';
import { DARK } from '@theme/shadows';

const styles = {
  ...COMMON,
  mode: 'dark',
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
    disabled: GREY[600],
    neutral: GREY[800],
    alternative: '#CCA377',
    contrastText: '#000',
    alternativeContrastText: '#000',
    link: '#ffffff',
    customTitle: '#ffffff',
  },
  disableButtonText: 'rgba(131, 131, 131, 1)',
  sidebar: {
    background: '#191919',
    border: '#fff',
    borderDivider: '#191919',
  },
  auth: {
    input: '#141414',
  },
  background: {
    paper: '#000',
    default: PRIMARY['darker'],
    neutral: GREY[500_16],
  },
  table: {
    primary: '#000',
    stickyHeader: '#1c1c1c',
    alternative: '#CCA377',
  },
  shadows: DARK,
  action: { active: GREY[500], ...COMMON.action },
  cards: {
    shadows: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    border: GREY[300],
    planRailBackground: '#EEEEEE',
    subscriptionBackground: '#1c1c1c',
    cardPrice: '#CCA377',
    checkGradient: {
      from: '#CCA377',
      to: '#CCA377',
    },
    integrationBackground: '#1c1c1c',
    integrationText: '#fff',
    settings: {
      background: '#34232B',
      shadow: 'none',
    },
    members: {
      background: '#34232B',
      shadow: 'none',
    },
    optimize: {
      notPushed: '#942430',
      pushed: '#CBA376',
    },
    plan: {
      essential: {
        background: 'linear-gradient(0deg, transparent 0%, rgb(37 32 25) 100%)',
        text: GRADIENTS.cardPrice,
        border: '#CCA377',
      },
      growth: {
        background: '#090909',
        text: GRADIENTS.cardPrice,
        border: '#3C3C3C',
      },
      enterprise: {
        background: 'linear-gradient(0deg, transparent 20%, #942430 100%)',

        text: 'linear-gradient(90deg, #fff 0%, #fff 86%)',
        border: '#942430',
      },
    },
  },
  switch: { active: '#CCA377', inactive: '#CBA37626' },
  dropdowns: {
    secondary: '#2a1c22',
    border: '#FFFFFF',
    darker: '#1c1c1c',
    parentContainer: '#1c1c1c',
    childContainer: '#2a1c22',
    singleOptionBackground: '#1c1c1c',
    multiOptionBackground: '#1c1c1c',
    checkBox: '#CCA377',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1c1c1c',
    },
  },
  loader: {
    spinner: {
      border: PRIMARY['main'],
      shadow: PRIMARY['dark'],
      shadowContrast: '#CBA376',
      background: '#00000099',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#CBA376',
    newText: '#68B0B0',
    dashboardsCardsText: '#fff',
    softIcons: '#FFF',
    divider: '#FFF',
    errorPlaceholder: '#C94747',
    errorMessages: '#F10000',
    badgeCounter: '#D42C9B',
    emailButton: '#2A1921',
  },
  icon: {
    primary: '#CBA376',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#CBA376',
      itemBackground: '#2B2B2B',
    },
    graphic: {
      subtitle: '#fff',
      containerBg: '#191919',
      gridColor: '#CBA37626',
      optimized: '#943432',
      nonOptimized: '#434C4C',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#CBA376',
        option: '#191919',
      },
    },
  },
  keywords: {
    input: '#422734A1',
    button: '#8D8495',
    buttonModal: '#4D3974',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#CBA376',
    icon: '#000',
  },
  buttons: {
    background: '#CBA376',
    text: '#000',
    backgroundSecondary: '#942430',
    borderSecondary: '#942430',
  },
  logoutButton: {
    background: COMMON.common.white,
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#422734A1',
    backgroundButton: '#3f3037',
  },
  stepper: {
    disabled: '#ECECEC',
    dividerBorder: GREY[300],
  },
  permissionsDenied: {
    gradient: {
      from: '#942430',
      to: '#CBA376',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#1c1c1c',
    iconBorder: '#cbd1d8',
    backgroundContainer: '#1c1c1c',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#fff',
    borderSelectedLogo: '#AEA6C4',
    backgroundSelectedLogo: '#8C83C333',
  },
  dialog: {
    background: '#c5cbd380',
    backgroundContainer: '#2a1921',
    resultBg: '#1c1c1c',
  },
  snackBar: {
    background: {
      success: PRIMARY['darker'],
      error: PRIMARY['darker'],
    },
  },
  subscription: {
    alternative: '#CBA376',
  },
  brandGuideline: {
    background: '#9B406C4D',
  },
  slider: {
    background: '#CBA376',
    border: '#942430',
  },
  textEditor: {
    outputBackground: '#5a4d53',
    delimiter: 'CBA376',
    attribute: '68B0B0',
    attributeValue: 'EC7B7B',
  },
  cmsIcons: {
    ...COMMON.cmsIcons,
    woocommerce: {
      ...COMMON.cmsIcons.woocommerce,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
      cardVersion: {
        stroke: 'none',
        svgFill: '#fff',
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: '#000',
      },
    },
    shopify: {
      ...COMMON.cmsIcons.shopify,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
      cardVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    magento: {
      ...COMMON.cmsIcons.magento,
      cardVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    akeneo: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    bigcommerce: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
      },
    },
    webflow: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        svgFill: '#000',
      },
    },
  },
  moderationCard: {
    iconBackground: '#1c1c1c',
    containerBackground: '#1c1c1c',
    iconContainerBackground: '#000',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboarding: {
    containerBg: '#1c1c1c',
    automaticCardBg: '#393939',
    cardBg: '#9B406C4D',
    cardBorder: '#fff',
    progressBg: '#CBA376',
    progressTransparency: '#494949',
    buttonBorder: '#CBA376',
    shopsContainerBg: '#422734A1',
    buttonText: '#000',
    rulesContainerBg: '#422734A1',
    rulesContainerSettingsBg: '#3f3037',
    buttonDisabled: '#CBA37626',
    disabledCardBg: '#9B406C1A',
    disabledCardBorder: '#fff',
    disabledCardText: '#fff',
    gscCardBg: '#9B406C4D',
  },
  tinymce: {
    background: '#34232B',
    backgroundHeader: '#5a4d53',
    html: '#CBA376',
    variables: '#68B0B0',
    values: '#EC7B7B',
  },
  feedback: {
    background: '#34232B',
    inputBackground: '#5a4d53',
  },
};
export default styles;
